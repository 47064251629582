import {useAuthStore} from "@/stores";
import {NavigationGuardProps} from "@/models";
import {RouteRecordRaw} from "vue-router";

// https://v3.router.vuejs.org/kr/guide/advanced/navigation-guards.html
export const navigationGuard: NavigationGuardProps = {
  beforeEach: (to, _, next) => {

    const isAuth = to.meta?.auth ?? true;
    if (isAuth) {
      const authStore = useAuthStore();

      return authStore.onCheck()
        .then(() => {
          return next();
        })
        .catch(() => {
          return next({name: "signIn"});
        });
    }
    return next();
  },
}

// Router Alignment
export const routes: RouteRecordRaw[] = [
  {
    path: "/",
    component: () => import("@/pages/BaseLayout.vue"),
    children: [
      {
        path: "auth",
        component: () => import("@/pages/auth/AuthLayout.vue"),
        children: [
          {
            path: "/signIn",
            name: "signIn",
            component: () => import("@/pages/auth/SignIn.vue"),
            meta: {auth: false}
          },
          {
            path: "/signUp",
            name: "signUp",
            component: () => import("@/pages/auth/SignUp.vue"),
            meta: {auth: false}
          }
        ]
      },
      {
        path: "",
        component: () => import("@/pages/home/HomeLayout.vue"),
        children: [
          {
            path: "",
            name: "home",
            component: () => import("@/pages/home/Home.vue"),
            meta: {auth: true},
          },
          {
            path: "/channel",
            name: "channel",
            component: () => import("@/pages/home/channel/Channel.vue"),
            meta: {auth: true},
          },
          {
            path: "/channel/:channelId",
            name: "channelItem",
            component: () => import("@/pages/home/channel/ChannelItem.vue"),
            meta: {auth: true}
          },
          {
            path: "/admin",
            name: "admin",
            component: () => import("@/pages/home/admin/Admin.vue"),
            meta: {auth: true}
          },
          {
            path: "/admin/:adminId",
            name: "adminItem",
            component: () => import("@/pages/home/admin/AdminItem.vue"),
            meta: {auth: true}
          },
          {
            path: "/user",
            name: "user",
            component: () => import("@/pages/home/user/User.vue"),
            meta: {auth: true}
          },
          {
            path: "/user/:userId",
            name: "userItem",
            component: () => import("@/pages/home/user/UserItem.vue"),
            meta: {auth: true}
          },
          {
            path: "/reward/:rewardSetId",
            name: "reward",
            component: () => import("@/pages/home/reward/Reward.vue"),
            meta: {auth: true}
          },
          {
            path: "/question/:questionSetId",
            name: "question",
            component: () => import("@/pages/home/question/Question.vue"),
            meta: {auth: true}
          },
          {
            path: "/game",
            name: "game",
            component: () => import("@/pages/home/game/Game.vue"),
            meta: {auth: true}
          },
          {
            path: "/game/:gameId/gameItem/:gameItem",
            name: "gameItem",
            component: () => import("@/pages/home/game/GameItem.vue"),
            meta: {auth: true}
          },
          {
            path: "/game/:gameId/gameDashBoard/:round",
            name: "gameDashBoard",
            component: () => import("@/pages/home/game/GameDashBoard.vue"),
            meta: {auth: true}
          },
        ],
      },
      {
        path: "/manage/migrate",
        name: "manageMigrate",
        component: () => import("@/pages/manage/Migrate.vue"),
        meta: {auth: false},
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
]
