// Routes
import {routes, navigationGuard} from "@/router"

// Composable
import {createRouter, createWebHistory, Router, RouteRecordRaw} from 'vue-router'

const createVueRouter = (_routes: RouteRecordRaw[]) => {
  const router: Router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: _routes,
  })

  if (navigationGuard.beforeEach) {
    router.beforeEach(navigationGuard.beforeEach)
  }

  if (navigationGuard.afterEach) {
    router.afterEach(navigationGuard.afterEach)
  }

  if (navigationGuard.beforeResolve) {
    router.beforeResolve(navigationGuard.beforeResolve)
  }

  return router;
}

export const vueRouter = createVueRouter(routes);
