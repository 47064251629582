import {GameRoundUserStoreStateProps} from "@/models";
import {defineStore} from "pinia";
import {apiRequest} from "@/repositories";
import cloneDeep from "lodash-es/cloneDeep";


export const useGameRoundUserStore = defineStore("gameRoundUser", {
  state: (): GameRoundUserStoreStateProps => ({
    items: [],
    query: {
      page: 1,
      perPage: 50,
      name: '',
      query: '',
    },
    pagination: {
      count: 0,
      next_flag: false,
      page: 1,
      page_numbers: [1],
      per_page: 1,
      prev_flag: false
    },

    count: 0,
    listLoading: false,

    form: null,
    formOrigin: null,
    formLoading: false,
    formSaving: false,

    options: [],
  }),
  getters: {},
  actions: {
    initForm() {
      this.form = {
        currentRound: null,
      };
      this.formOrigin = cloneDeep(this.form)
    },
    resetForm() {
      this.form = cloneDeep(this.formOrigin);
    },

    async onList(gameId: number, round: number): Promise<void> {
      this.listLoading = true
      return apiRequest.get({
        url: `/api/game/${gameId}/round/${round}/user`,
        onError: false,
        params: this.query,
      })
        .then((data) => {
          //  api data 에서 뭐가 떨어질지 모르겠음 , 일단은 값이 있는 res 사용
          const {apiData} = data;
          this.items = apiData.game_user_items;
          this.pagination = apiData.pagination ?? {
            count: 17,
            next_flag: false,
            page: 1,
            page_numbers: [1],
            per_page: 50,
            prev_flag: false
          };


          return Promise.resolve(apiData);
        })
        .catch((error) => {
          return Promise.reject(error);
        })
        .finally(() => {


          this.listLoading = false;
        })
    },

    async onAddUserPoint(gameId: number, round: number, userId: number, point: number) {
      this.formSaving = true;
      return apiRequest.put({
        url: `/api/game/${gameId}/round/${round}/user/${userId}/point`,
        data: {
          point: point
        }
      })
        .then((data) => {
          this.formOrigin = cloneDeep(this.form);

          return Promise.resolve(data.apiData);
        })
        .catch((error) => {
          return Promise.reject(error.apiData);
        })
        .finally(() => {
          this.formSaving = false;
        })
    },

    async onAdminCheck(gameId: number, round: number, userId: number, flag: boolean) {
      this.formSaving = true;
      return apiRequest.put({
        url: `/api/game/${gameId}/round/${round}/user/${userId}/admin-check`,
        data: {
          admin_check_flag: flag
        }
      })
        .then((data) => {
          this.formOrigin = cloneDeep(this.form);

          return Promise.resolve(data.apiData);
        })
        .catch((error) => {
          return Promise.reject(error.apiData);
        })
        .finally(() => {
          this.formSaving = false;
        })
    },



  }

})
