import { GameRoundStoreStateProps } from "@/models";
import {defineStore} from "pinia";
import {apiRequest} from "@/repositories";
import cloneDeep from "lodash-es/cloneDeep";


export const useGameRoundStore = defineStore("gameRound", {
  state: (): GameRoundStoreStateProps => ({
    items: [],
    roundItems: null,

    query: {
      page: 1,
      perPage: 50,
      name: ''
    },
    pagination: {
      count: 0,
      next_flag: false,
      page: 1,
      page_numbers: [1],
      per_page: 1,
      prev_flag: false
    },

    count: 0,
    listLoading: false,

    form: null,
    formOrigin: null,
    formLoading: false,
    formSaving: false,

    options: [],
  }),
  getters: {},
  actions: {
    initForm() {
      this.form = {
        currentRound: null,
      };
      this.formOrigin = cloneDeep(this.form)
    },
    resetForm() {
      this.form = cloneDeep(this.formOrigin);
    },

    async onList(gameId: number, round: number): Promise<void> {
      this.listLoading = true
      return apiRequest.get({
        url: `/api/game/${gameId}/round/${round}`,
        onError: false,
      })
        .then((data) => {
          const {apiData} = data;
          this.roundItems = apiData.game_round_info;
          this.pagination = apiData.pagination;

          return Promise.resolve(apiData);
        })
        .catch((error) => {
          return Promise.reject(error);
        })
        .finally(() => {
          this.listLoading = false;
        })
    },

    async onGetOption(gameId: number): Promise<void> {
      this.listLoading = true
      return apiRequest.get({
        url: `/api/game/${gameId}/round/options`,
        onError: false,
      })
        .then((data) => {
          const {apiData} = data;
          this.options = apiData.game_round_options;
          this.pagination = apiData.pagination;

          return Promise.resolve(apiData);
        })
        .catch((error) => {
          return Promise.reject(error);
        })
        .finally(() => {
          this.listLoading = false;
        })
    },

    async onGameStatusUpdate(gameId: number, status: string) {
      this.formSaving = true;
      return apiRequest.put({
        url: `/api/game/${gameId}/status/${status}`,
      })
        .then((data) => {
          console.log(data.apiData);
          this.formOrigin = cloneDeep(this.form);

          return Promise.resolve(data.apiData);
        })
        .catch((error) => {
          return Promise.reject(error.apiData);
        })
        .finally(() => {
          this.formSaving = false;
        })
    },


  }

})
