// Composable
import {defineStore} from "pinia";

import camelcase from "lodash-es/camelCase";

import {ManagementStoreGetLanguageItemProps, ManagementStoreGetOptionItemProps, ManagementStoreLanguageData, ManagementStoreListOptionsProps, ManagementOptionItem, ManagementStoreOptionMaps, ManagementStoreStateProps} from "@/models";
import {apiRequest} from "@/repositories";


export const useManagementStore = defineStore("management", {
  state: (): ManagementStoreStateProps => ({
    options: {

    },

    lang: "ko-kr",
    languages: {},
  }),
  getters: {},
  actions: {
    // private
    _bindingOptions(src?: { [name: string]: any[] } | null): void {
      if (src === undefined || src === null) {
        return;
      }

      this.options = {};
      for (const [key, _v] of Object.entries(src)) {
        const value = _v as any[];

        const items: ManagementOptionItem[] = value.map((item) => {
          const extra: { [key: string]: any } = {};

          if (item.extra) {
            for (const [extraKey, extraValue] of Object.entries(item.extra)) {
              extra[camelcase(extraKey)] = extraValue;
            }
          }

          for (const [k, v] of Object.entries(item)) {
            if (["key", "value"].includes(k) || k === "extra") {
              continue;
            }

            extra[camelcase(k)] = v;
          }

          return {
            key: item.key,
            value: item.value,
            extra: extra,
          };
        });

        const maps: ManagementStoreOptionMaps = {};
        for (const item of items) {
          maps[item.key] = item;
        }

        this.options[key] = { items, maps };
      }

      console.log(this.options)
    },

    _bindingLanguages(src?: ManagementStoreLanguageData | null): void {
      if (src === undefined || src === null) {
        return;
      }
      this.languages = src;
    },

    // api
    async onGetOptions(): Promise<void> {
      return apiRequest.get({
        url: "/api/management/options",
        onError: false,
      }).then((data) => {
        this._bindingOptions(data.apiData)
      })
    },

    async onGetLanguage(): Promise<void> {
      return apiRequest.get({
        url: "/api/management/language",
        onError: false,
      }).then((data) => {
        this._bindingLanguages(data.apiData)
      });
    },

    // functions
    listOptions(props: ManagementStoreListOptionsProps): ManagementOptionItem[] {
      let items: ManagementOptionItem[] = [...(this.options[props.name] ?? {}).items ?? []];

      // all
      const allFlag = props.all ?? false;
      if (allFlag) {
        items.unshift({
          key: props.allInfo?.key ?? 0,
          value: props.allInfo?.value ?? '전체',
          extra: props.allInfo?.extra ?? {},
        })
      }

      // exclude
      if (props.excludeKeys && props.excludeKeys.length > 0) {
        items = items.filter((item) => !props.excludeKeys!.includes(item.key))
      }

      // prepend
      if (props.prepend) {
        const _t: ManagementOptionItem[] = props.prepend.map((item) => ({...item, extra: item.extra ?? {}}));
        items = _t.concat(items);
      }

      // append
      if (props.append) {
        const _t: ManagementOptionItem[] = props.append.map((item) => ({...item, extra: item.extra ?? {}}));
        items = items.concat(_t)
      }

      return items;
    },
    getOptionItem(props: ManagementStoreGetOptionItemProps): ManagementOptionItem | null {
      const _m = (this.options[props.name] ?? {}).maps ?? {};
      return _m[props.key] ?? null;
    },
    getOptionValue(props: ManagementStoreGetOptionItemProps): string | number {
      const _t = this.getOptionItem(props);
      return _t === null ? "" : _t.value;
    },
    getOptionExtra(props: ManagementStoreGetOptionItemProps): any {
      const _t = this.getOptionItem(props);
      return _t === null ? {} : _t.extra;
    },

    getLanguageValue(props: ManagementStoreGetLanguageItemProps): string {
      const _l = props.lang ?? this.lang;
      return (this.languages[_l] ?? {})[props.key] ?? "";
    }
  }
});
